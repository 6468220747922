import React, { useState } from "react";
import Stars from "./stars";
const ChamberReviews = () => {

    const [isMore, setMore] = useState(false)
    const handleClick = () => {
        return setMore(!isMore)
    }

    const reviewsChamb = [
        {
            name: "Susan T.",
            date: "03/01/2023",
            text: "Lamar Home Services is great! I needed some siding replaced/repaired and repainted. Squirrels had eaten holes in siding at my roofline. I put out this need on Angie's List. Cruz Castillo contacted me immediately for an appointment to see the job. Yes, it was a small job, but he walked around the entire house noting any protruding nails. I also asked him If he would re-glue a piece of window frame. He did the job! And I am completely satisfied with the result. I plan to call Cruz for any future fixes I may need.",
            stars: 5
        },

        {
            name: "Sam Mylius",
            date: "03/06/2022",
            text: "Cruz performed all tasks agreed upon and did a few extra small repairs as well. He did the work competently and on time.",
            stars: 5
        },

        {
            name: "Elena, Anthony",
            date: "02/05/2022",
            text: "Our house had to be practically rebuilt from A to Z. Mr. Cruz was very knowledgeable, capable, and extremely helpful. He got our house built back up with a high-quality job and a very reasonable price. If anyone is looking for someone who is capable, knowledgeable, and with excellent results, call Mr. Cruz at Lamar Home Solutions. Mr. Cruz is thorough, detailed, and customer-oriented. We enjoyed working with him. The entire project was so clean, every day he took great care of our home as it progressed. We sincerely recommend Lamar Home Solutions and hope to have Lamar Home Solutions back for other projects. I can’t say enough about this company. It has been a pleasure to work with Lamar Home Solutions. Mr. Cruz is professional and very patient.",
            stars: 5
        },

        {
            name: "Jim",
            date: "18/08/2022",
            text: "Cruz performed all tasks agreed upon and did a few extra small repairs as well. He did the work competently and on time.",
            stars: 5
        },

        {
            name: "Oswald",
            date: "14/06/2022",
            text: "On first meeting Cruz immediately started assessing & providing costs on first visit. I like his enthusiasm, & attention to detail that I added additional projects to original work scope. James Louis, his foreman kept the worksite organized and on track. They maintained a clean & precise worksite. Very happy with the overall quality.",
            stars: 5
        },

        {
            name: "Gerald",
            date: "09/10/2022",
            text: "Owner onsite-many times during the entire process ( 5 days ) . Every thing done as promised. Met all expectations!",
            stars: 5
        },

        {
            name: "james",
            date: "02/08/2022",
            text: "They were on time, stood by their work, cleaned up the job site and even upgraded part of the roof and didn't charge extra for it! My wife loved their work and is wanting them to do some other projects to the house. JC will tell you the truth and stand by his work.",
            stars: 5
        },




    ]

    return (
        <section className="w-full py-20">
            <h2 className="text-center">Our Reviews</h2>



            {
                isMore ?
                    reviewsChamb.map((item, index) => {
                        return (
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                                <div className="flex justify-between p-5">
                                    <div className="flex">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                            alt="userphotodeatult"
                                            className="w-[50px] shadow-md rounded-[50%]" />
                                        <div className="ml-3">
                                            <span className="font-medium">{item.name}</span>
                                            <Stars
                                                score={item.stars}
                                            />
                                        </div>
                                    </div>
                                    <span className="">{item.date}</span>

                                </div>
                                <div className="p-5">
                                    <p>{item.text}</p>
                                </div>

                            </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0, 4).map((item, index) => {
                        return (
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                                <div className="flex justify-between p-5">
                                    <div className="flex">
                                        <img
                                            src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                            alt="userphotodeatult"
                                            className="w-[50px] shadow-md rounded-[50%]" />
                                        <div className="ml-3">
                                            <span className="font-medium" >{item.name}</span>
                                            <Stars
                                                score={item.stars}
                                            />
                                        </div>
                                    </div>
                                    <span className="">{item.date}</span>

                                </div>
                                <div className="p-5">
                                    <p>{item.text}</p>
                                </div>
                            </section>
                        )
                    })
            }


            <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                {
                    isMore ?
                        "Show Less"
                        :
                        "Show More"
                }
            </button>
        </section>
    );

}

export default ChamberReviews